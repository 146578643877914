setupSiteHeaderLinkTracking();
function setupSiteHeaderLinkTracking() {
  const header = document.getElementsByClassName("site-header")[0];
  if (!header) return;
  const ctaLink = header.getElementsByClassName("site-header__link--cta")[0];
  window.ExpressenAnalytics = window.ExpressenAnalytics || [];
  if (ctaLink) ctaLink.addEventListener("click", trackLinkClick);
  function trackLinkClick() {
    window.ExpressenAnalytics.push(analytics => {
      analytics.track.customActive("navigation", "click", "buy_premium");
    });
  }
}